import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";

const Services = () => (
  <Layout>
    <Seo title="Servicios" />
    <Header />
    <main className="services">
      <div className="description">
        <h1>Servicios</h1>
        <p>
          En Cenfarte ofrecemos servicios integrales de distribución de
          medicamentos, productos sanitarios y de parafarmacia en la mitad norte
          del país.
        </p>
        <p>
          Conscientes de que las necesidades de las Oficinas de Farmacia se
          extienden a muchas otras esferas, en Cenfarte hemos querido ayudar a
          nuestros socios tejiendo una red de acuerdos comerciales con empresas
          proveedoras de servicios complementarios que les permitan disfrutar de
          condiciones ventajosas en el momento de su contratación.
        </p>
      </div>
      <div className="services-container">
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon_instalaciones.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Instalaciones y equipamiento</h2>
        </div>
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon_gestion.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Gestión</h2>
        </div>
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon_transmision.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Transmisión de Oficina de Farmacia</h2>
        </div>
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon_seguros.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Seguros</h2>
        </div>
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon-naturales.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Distribución de productos naturales</h2>
        </div>
        <div className="service">
          <div className="icon">
            <StaticImage
              src="../images/general/services/icon_prevencion.svg"
              alt=""
              placeholder="blurred"
              layout="fixed"
              width={120}
            />
          </div>
          <h2>Prevención de riesgos laborales</h2>
        </div>
      </div>
    </main>
  </Layout>
);

export default Services;
